import { mockPromise } from "@utils";
import request from "../request";

const mode = import.meta.env.VITE_AUDIT_URL_MODE;

const urls = {
  orderList: "/order/list",
  case: "/order/",
  classifyOrder: "/order/classify-order",
  pricing: "/pricing",
  orderUpdateUrl: (orderId: any) => `order/${orderId}`,
  dispatchUrl: (orderId: any) => `order/${orderId}/dispatch`,
  overrideDefaultsUrl: (orderId: any) => `order/${orderId}/pricing/overrideDefaults`,
  patientHistory: "/order/user/history",
  documentUrl: (orderId: any) => `/order/${orderId}/document`,
  documentRemoveUrl: (orderId: any, id: number) => `order/${orderId}/document/${id}`,
  submitAuditUrl: `/cloudfn/fleet-audit-${mode}-orderFleetAudit`,
  getAuditUrl: `/cloudfn/fleet-audit-${mode}-getFleetAuditData/?orderId=`,
  cancelOrder: (orderId: any) => `order/${orderId}/cancel`,
  moveScheduleOrderToUnfulfilled: (orderId: any) => `order/${orderId}/moveScheduleOrderToUnfulfilled`,
  uploadDocumentLink: "/cloudfn/sendUploadDocumentLink",
  saathiPricing: "/pricing/saathi/all",
  updateScheduledDTMApi: (orderId: any) => `order/order-schedule/${orderId}`,
};

export const getAllCase = (query?: string) => {
  const response = request("GET", `${urls.orderList}${query}`);
  return response;
};

export const getCase = (caseId?: string) => {
  const response = request("GET", `${urls.case}${caseId}`);
  return response;
};

export const createCase = (payload: any) => {
  const response = request("POST", urls.case, payload);
  return response;
};

export const classifyOrder = (payload: any) => {
  const response = request("POST", urls.classifyOrder, payload);
  return response;
};

export const orderPricing = (query?: string) => {
  const response = request("GET", `${urls.pricing}${query}`);
  return response;
};

export const patientCaseHistory = (query?: string) => {
  const response = request("GET", `${urls.patientHistory}${query}`);
  return response;
};

export const dispatchCase = (payload: any) => {
  const url = urls.dispatchUrl(payload?.orderId);
  const response = request("PATCH", url, payload);
  // const response = mockPromise();
  return response;
};

export const updateCase = (payload: any) => {
  const url = urls.orderUpdateUrl(payload?.orderId);
  const response = request("PATCH", url, payload);
  return response;
};

export const cancelOrder = (payload: any) => {
  const url = urls.cancelOrder(payload?.orderId);
  const response = request("PATCH", url, payload);
  return response;
};

export const moveScheduleOrderToUnfulfilled = (payload: any) => {
  const url = urls.moveScheduleOrderToUnfulfilled(payload?.orderId);
  const response = request("PATCH", url, payload);
  return response;
};

export const overrideDefaults = (payload: any) => {
  const url = urls.overrideDefaultsUrl(payload?.orderId);

  // ~ Removing OrderId from payload
  payload?.orderId && delete payload?.orderId;

  const response = request("PATCH", url, payload);
  return response;
};

export const uploadDocuments = (payload: any) => {
  const { orderId } = payload;
  delete payload["orderId"];

  const url = urls.documentUrl(orderId);
  const response = request("POST", url, payload);
  return response;
};

export const removeDocuments = (payload: any) => {
  const { orderId, id } = payload;
  const url = urls.documentRemoveUrl(orderId, id);
  const response = request("DELETE", url);
  return response;
};

export const saveAuditData = (data: any) => {
  const response = request("POST", urls.submitAuditUrl, data);
  return response;
};

export const fetchAuditData = (orderId: string) => {
  const url = `${urls.getAuditUrl}${orderId}`;
  const response = request("GET", url);
  return response;
};

export const sendUploadDocumentLink = (payload: any) => {
  const response = request("POST", urls.uploadDocumentLink, payload);
  return response;
};

export const updateCaseScheduledDTM = (payload: any, orderId:any) => {
  const url = urls.updateScheduledDTMApi(orderId);
  const response = request("PATCH", url, payload);
  return response;
};

export const getSaathiPricing = (payload: any) => {
  const response = request("POST", urls.saathiPricing, payload);
  // const response = mockPromise({
  //   data: {
  //     city: "HYD",
  //     country: "IN",
  //     membershipType: "saathi_silver",
  //     totalDistanceInMeters: 179494,
  //     isDefaultPricing: false,
  //     pricingList: [
  //       {
  //         fleetType: "als",
  //         fleetSubType: "elite",
  //         fleetFare: 204494,
  //         fleetVariant: "alsElite",
  //         addOnsFare: 71797.6,
  //         addOnsPriceBreakup: [
  //           {
  //             id: "1003_O2",
  //             price: 200,
  //             priceUnit: "per_km",
  //             unitValue: 179.494,
  //             quantity: 2,
  //             totalAmount: 71797.6,
  //             normalizedPricePerQuantity: 35898.8,
  //           },
  //         ],
  //         costToOwner: 276291.6,
  //       },
  //       {
  //         fleetType: "bls",
  //         fleetSubType: "elite",
  //         fleetFare: 204494,
  //         fleetVariant: "blsElite",
  //         addOnsFare: 71797.6,
  //         addOnsPriceBreakup: [
  //           {
  //             id: "1003_O2",
  //             price: 200,
  //             priceUnit: "per_km",
  //             unitValue: 179.494,
  //             quantity: 2,
  //             totalAmount: 71797.6,
  //             normalizedPricePerQuantity: 35898.8,
  //           },
  //         ],
  //         costToOwner: 276291.6,
  //       },
  //       {
  //         fleetType: "als",
  //         fleetSubType: "supreme",
  //         fleetFare: 204494,
  //         fleetVariant: "alsSupreme",
  //         addOnsFare: 71797.6,
  //         addOnsPriceBreakup: [
  //           {
  //             id: "1003_O2",
  //             price: 200,
  //             priceUnit: "per_km",
  //             unitValue: 179.494,
  //             quantity: 2,
  //             totalAmount: 71797.6,
  //             normalizedPricePerQuantity: 35898.8,
  //           },
  //         ],
  //         costToOwner: 276291.6,
  //       },
  //       {
  //         fleetType: "bls",
  //         fleetSubType: "supreme",
  //         fleetFare: 204494,
  //         fleetVariant: "blsSupreme",
  //         addOnsFare: 71797.6,
  //         addOnsPriceBreakup: [
  //           {
  //             id: "1003_O2",
  //             price: 200,
  //             priceUnit: "per_km",
  //             unitValue: 179.494,
  //             quantity: 2,
  //             totalAmount: 71797.6,
  //             normalizedPricePerQuantity: 35898.8,
  //           },
  //         ],
  //         costToOwner: 276291.6,
  //       },
  //       {
  //         fleetType: "als",
  //         fleetSubType: "standard",
  //         fleetFare: 204494,
  //         fleetVariant: "als",
  //         addOnsFare: 71797.6,
  //         addOnsPriceBreakup: [
  //           {
  //             id: "1003_O2",
  //             price: 200,
  //             priceUnit: "per_km",
  //             unitValue: 179.494,
  //             quantity: 2,
  //             totalAmount: 71797.6,
  //             normalizedPricePerQuantity: 35898.8,
  //           },
  //         ],
  //         costToOwner: 276291.6,
  //       },
  //       {
  //         fleetType: "als",
  //         fleetSubType: "standard",
  //         fleetFare: 204494,
  //         fleetVariant: "alsStandard",
  //         addOnsFare: 71797.6,
  //         addOnsPriceBreakup: [
  //           {
  //             id: "1003_O2",
  //             price: 200,
  //             priceUnit: "per_km",
  //             unitValue: 179.494,
  //             quantity: 2,
  //             totalAmount: 71797.6,
  //             normalizedPricePerQuantity: 35898.8,
  //           },
  //         ],
  //         costToOwner: 276291.6,
  //       },
  //       {
  //         fleetType: "bls",
  //         fleetSubType: "standard",
  //         fleetFare: 204494,
  //         fleetVariant: "bls",
  //         addOnsFare: 71797.6,
  //         addOnsPriceBreakup: [
  //           {
  //             id: "1003_O2",
  //             price: 200,
  //             priceUnit: "per_km",
  //             unitValue: 179.494,
  //             quantity: 2,
  //             totalAmount: 71797.6,
  //             normalizedPricePerQuantity: 35898.8,
  //           },
  //         ],
  //         costToOwner: 276291.6,
  //       },
  //       {
  //         fleetType: "bls",
  //         fleetSubType: "standard",
  //         fleetFare: 204494,
  //         fleetVariant: "blsStandard",
  //         addOnsFare: 71797.6,
  //         addOnsPriceBreakup: [
  //           {
  //             id: "1003_O2",
  //             price: 200,
  //             priceUnit: "per_km",
  //             unitValue: 179.494,
  //             quantity: 2,
  //             totalAmount: 71797.6,
  //             normalizedPricePerQuantity: 35898.8,
  //           },
  //         ],
  //         costToOwner: 276291.6,
  //       },
  //     ],
  //   },
  // });
  return response;
};
